import "./loadjs";
import "./requestAnimationFrame";
import "./CustomEvent";
import Language from "./Language";
import SelectionList from "./SelectionList";
import SelectionListOption from "./SelectionListOption";

// Define SelectionList
customElements.define("selection-list", SelectionList, { extends: "input" });

window.SelectionList = SelectionList;
window.SelectionListOption = SelectionListOption;

var ew = {
    PAGE_ID: "", // Page ID // To be updated in page
    RELATIVE_PATH: "", // Relative path // To be updated in page
    MULTIPLE_OPTION_SEPARATOR: ",",
    GENERATE_PASSWORD_UPPERCASE: true,
    GENERATE_PASSWORD_LOWERCASE: true,
    GENERATE_PASSWORD_NUMBER: true,
    GENERATE_PASSWORD_SPECIALCHARS: true,
    CONFIRM_CANCEL: true,
    ROWTYPE_ADD: 2,
    ROWTYPE_EDIT: 3,
    UNFORMAT_YEAR: 50,
    LAZY_LOAD_RETRIES: 3,
    AJAX_DELAY: 5,
    LOOKUP_DELAY: 250,
    MAX_OPTION_COUNT: 3,
    USE_OVERLAY_SCROLLBARS: true, // For responsive tables
    Language: Language, // Class
    language: null, // Language object
    vars: null,
    googleMaps: [],
    addOptionDialog: null,
    emailDialog: null,
    importDialog: null,
    modalDialog: null,
    modalLookupDialog: null,
    autoSuggestSettings: {
        highlight: true,
        hint: true,
        minLength: 1,
        trigger: "click",
        debounce: 250,
        delay: 0, // For loading more results
        templates: { // Custom templates for Typeahead (notFound, pending, header, footer, suggestion)
            footer: '<div class="tt-footer"><a href="#" class="tt-more"></a></div>' // "footer" template
        }
    },
    lightboxSettings: {
        transition: "none",
        photo: true,
        opacity: 0.5
    },
    importUploadOptions: {
        maxFileSize: 10000000,
        maxNumberOfFiles: 10
    },
    sweetAlertSettings: {
        showClass: {
            popup: "swal2-noanimation",
            backdrop: "swal2-noanimation"
        },
        hideClass: {
            popup: "",
            backdrop: ""
        },
        customClass: {
            container: "ew-swal2-container",
            popup: "ew-swal2-popup",
            header: "ew-swal2-header",
            title: "ew-swal2-title",
            closeButton: "ew-swal2-close-button",
            icon: "ew-swal2-icon",
            image: "ew-swal2-image",
            content: "ew-swal2-content",
            input: "ew-swal2-input",
            actions: "ew-swal2-actions",
            confirmButton: "ew-swal2-confirm-button",
            cancelButton: "ew-swal2-cancel-button",
            footer: "ew-swal2-footer"
        }
    },
    selectOptions: {
        // Select2 options
        allowClear: true,
        theme: "bootstrap4",
        width: "style",
        minimumResultsForSearch: 20,
        escapeMarkup: (v) => v,
        // Custom options
        debounce: 250, // For ajax.delay, see https://select2.org/data-sources/ajax#rate-limiting-requests
        customOption: true,
        containerClass: "d-table",
        rowClass: "d-table-row",
        cellClass: "d-table-cell text-nowrap",
        iconClass: "custom-control-label"
    },
    toastOptions: {
        position: "topRight", // topRight|topLeft|bottomRight|bottomLeft
    },
    DOMPurifyConfig: {},
    sanitize: function(str) {
        return DOMPurify.sanitize(str, this.DOMPurifyConfig);
    },
    sanitizeFn: null, // For Bootstrap Tooltips and Popovers
    PDFObjectOptions: {},
    chartConfig: {},
    spinnerClass: "spinner-border text-primary", // spinner-border or spinner-grow
    jsRenderHelpers: {},
    autoHideSuccessMessage: true,
    autoHideSuccessMessageDelay: 5000,
    searchOperatorChanged: function() {},
    setLanguage: function() {},
    addOptionDialogShow: function() {},
    modalLookupShow: function() {},
    importDialogShow: function() {},
    toggleSearchOperator: function() {},
    togglePassword: function() {},
    sort: function() {},
    clickMultiCheckbox: function() {},
    export: function() {},
    exportWithCharts: function() {},
    setSearchType: function() {},
    emailDialogShow: function() {},
    selectAll: function() {},
    selectAllKey: function() {},
    submitAction: function() {},
    addGridRow: function() {},
    confirmDelete: function() { return false; },
    deleteGridRow: function() { return false; }
};

/**
 * Add spinner
 */
ew.addSpinner = function() {
    if (document.getElementById("ew-page-spinner"))
        return;
    var div = document.createElement("div");
    div.id ="ew-page-spinner";
    div.setAttribute("class", ew.spinnerClass);
    div.setAttribute("role", "status");
    div.innerHTML = '<span class="sr-only">' + (ew.language ? ew.language.phrase("Loading") : "Loading...") + '</span>';
    if (document.body)
        document.body.appendChild(div);
};

/**
 * Remove spinner
 */
ew.removeSpinner = function() {
    var el = document.getElementById("ew-page-spinner");
    if (el)
        el.parentNode.removeChild(el);
};

/**
 * Init grid upper/lower panel
 *
 * @param {HTMLElement} el - Element
 */
ew.initGridPanel = function(el) {
    if (el.dataset.isset)
        return;
    var html = "";
    for (var i = 0; i < el.children.length; i++) {
        html = el.children[i].innerHTML.trim();
        if (html !== "")
            break;
    }
    if (html === "")
        el.classList.add("d-none");
    el.dataset.isset = true;
};

/**
 * Init grid upper and lower panels
 */
ew.initGridPanels = function() {
    Array.prototype.forEach.call(document.querySelectorAll(".ew-grid-upper-panel, .ew-grid-lower-panel"), this.initGridPanel);
}

// Request animation frame to init grid lower and upper panels
var _initGridPanelsReq;
function _initGridPanels(timestamp) {
    ew.initGridPanels();
    _initGridPanelsReq = requestAnimationFrame(_initGridPanels);
}
_initGridPanelsReq = requestAnimationFrame(_initGridPanels);

// DOM content loaded
document.addEventListener("DOMContentLoaded", function() {
    ew.addSpinner();
    ew.initGridPanels();
    cancelAnimationFrame(_initGridPanelsReq);
    window.loadjs.done("dom");
});

/**
 * Overlay scrollbars options
 */
ew.overlayScrollbarsOptions = {
    className: "os-theme-dark",
    sizeAutoCapable: true,
    scrollbars: {
        autoHide: "leave",
        clickScrolling: true
    }
};

// All bundle IDs
ew.bundleIds = ["dom", "head"];

/**
 * Initiate script load (async in series) and register bundle
 * @param {(string|string[])} paths - The file paths
 * @param {(string|Function|Object)} [arg1] - The (1) bundleId or (2) success
 *   callback or (3) object literal with success/error arguments, numRetries,
 *   etc.
 * @param {(Function|Object)} [arg2] - The (1) success callback or (2) object
 *   literal with success/error arguments, numRetries, etc.
 */
ew.loadjs = function(paths, arg1, arg2) {
    let bundleId,
        args;
    // bundleId (if string)
    if (arg1 && arg1.trim)
        bundleId = arg1;
    // args (default is {})
    args = (bundleId ? arg2 : arg1) || {};
    if (bundleId && bundleId != "load" && !ew.bundleIds.includes(bundleId))
        ew.bundleIds.push(bundleId);
    paths = Array.isArray(paths) ? paths : [paths];
    paths = paths.filter(path => path && (!Array.isArray(path) || path.length)); // Valid paths
    if (args.call) // Accept function as argument
        args = { success: args };
    args = { ...args, returnPromise: true };
    let clone = { ...args },
        p = Promise.resolve();
    delete clone.success;
    paths.forEach((path, i, ar) => {
        if (i == ar.length - 1) // Last
            p = p.then(() => window.loadjs(path, bundleId || args, bundleId ? args : null).catch(paths => console.log(paths)));
        else
            p = p.then(() => window.loadjs(path, clone).catch(paths => console.log(paths)));
    });
    return p;
};

/**
 * Initiate script load (async in series) when dependencies have been satisfied
 * @param {(string|string[])} deps - List of bundle ids
 * @param {(string|string[])} paths - The file paths
 * @param {(string|Function|Object)} [arg1] - The (1) bundleId or (2) success
 *   callback or (3) object literal with success/error arguments, numRetries,
 *   etc.
 * @param {(Function|Object)} [arg2] - The (1) success callback or (2) object
 *   literal with success/error arguments, numRetries, etc.
 */
ew.ready = function(deps, paths, arg1, arg2) {
    window.loadjs.ready(deps, function() {
        ew.loadjs(paths, arg1, arg2);
    });
};

// Global client script
loadjs.ready("head", function() {
    ew.clientScript();
});

// Global startup script
loadjs.ready("foot", function() {
    ew.startupScript();
    loadjs.done("load");
});

/**
 * Render client side template, use the HTML in DOM and return the HTML
 *
 * @param {jQuery} tmpl Template
 * @param {Object} data Data
 * @returns HTML string
 */
ew.renderTemplate = function(tmpl, data) {
    var $ = jQuery, $tmpl = (tmpl && tmpl.render) ? tmpl : $(tmpl);
    if (!$tmpl.render)
        return;
    var args = {$template: $tmpl, data: data};
    $(document).trigger("rendertemplate", [args])
    var html = $tmpl.render(args.data, ew.jsRenderHelpers),
        method = args.$template.data("method"),
        target = args.$template.data("target");
    if (html && method && target) // Render by specified method to target
        $(html)[method](target);
    else if (html && !method && target) // No method, render as inner HTML of target
        $(target).html(html);
    else if (html && !method && !target) // No method and target, render locally
        $tmpl.parent().append(html);
    return html;
};

/**
 * Render all client side templates
 *
 * @param {*} e Event
 */
ew.renderJsTemplates = function(e) {
    var $ = jQuery, ids = {}, el = (e && e.target) ? e.target : document;
    $(el).find(".ew-js-template").sort(function (a, b) {
        a = parseInt($(a).data("seq"), 10) || 0;
        b = parseInt($(b).data("seq"), 10) || 0;
        if (a > b) {
            return 1;
        } else if(a < b) {
            return -1;
        } else {
            return 0;
        }
    }).each(function(index) {
        var $this = $(this), name = $this.data("name"), data = $this.data("data");
        if (data && typeof data == "string") {
            data = ew.vars[data] || window[data]; // Get data from ew.vars or global
            if (!data) // Data not found (e.g. no header)
                return;
        }
        if (name) {
            if (!$.render[name]) { // Render the first template of any named template only
                $.templates(name, $this.text());
                ew.renderTemplate($this, data);
            }
        } else {
            ew.renderTemplate($this, data);
        }
    });
};

export default ew;